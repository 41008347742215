import { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useRouter } from "next/router"
import ReactModal from "react-modal"
import YouTube from "react-youtube"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import { convertYoutubeUrlToId } from "@lib/utils"

import Image from "@components/image"

// ---------------------------------------------------------

import {
  has_caption,
  rounded_edges,
  video_container,
  video_wrapper,
  video_modal,
  video_modal_overlay,
  video_modal_container,
  video_modal_close_icon_container,
  video_modal_close_icon,
  video_thumbnail,
  video_thumbnail_container,
  video_thumbnail_play_button,
  video_thumbnail_play_button_icon
} from "./styles.module.scss"

const Video = (props) => {
  let { caption, className, title, videoUrl, videoFile, loopAndAutoplay, roundedEdges, thumbnail } =
    props

  // -------------------------------------------------------

  const wrapperClasses = classNames(video_wrapper, {
    [has_caption]: caption
  })

  const classes = classNames(video_container, {
    [className]: className
  })

  // -------------------------------------------------------

  const router = useRouter()
  const intervalRef = useRef()
  const [fiftyPercent, setFiftyPercent] = useState(false)
  const [started, setStarted] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const handleStateChange = (e) => {
    const { data, target } = e

    if (data === 1) {
      intervalRef.current = setInterval(() => {
        const progress = target.getCurrentTime() / target.getDuration()
        handleProgress({ played: progress || 0 })
      }, 1000)
    }

    if (data === 2 || data === 0) {
      clearInterval(intervalRef.current)
    }
  }

  const handleOnPlay = () => {
    if (!started) {
      setStarted(true)
    }
  }

  const handleProgress = ({ played }) => {
    if (!fiftyPercent && played >= 0.5) {
      setFiftyPercent(true)
    }
  }

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      modestbranding: 1,
      rel: 0,
      color: "white"
    }
  }

  // ---------------------------------------------------------

  useEffect(() => {
    const munchkinLinkClick = (status) => {
      if (typeof window !== "undefined" && window.Munchkin) {
        window.Munchkin.munchkinFunction("clickLink", {
          href: `${router.asPath} > Video ${status}: ${title}`
        })
      }
    }

    if (started && !fiftyPercent) {
      munchkinLinkClick("started")
    }

    if (fiftyPercent) {
      munchkinLinkClick("watched 50%")
    }
  }, [fiftyPercent, router.asPath, started, title])

  // ---------------------------------------------------------

  const videoId = convertYoutubeUrlToId(videoUrl)
  if (!videoId && !videoFile?.url) return null

  const getVideo = () => {
    /* eslint-disable jsx-a11y/media-has-caption */

    return (
      <div key={videoId || videoFile?.url} className={wrapperClasses}>
        {loopAndAutoplay ? (
          <div className={classes}>
            <video
              autoPlay
              loop="loop"
              muted="muted"
              playsInline="playsInline"
              allowFullScreen={false}
              className={roundedEdges ? rounded_edges : null}
            >
              <source src={videoFile?.url} type="video/mp4"></source>
            </video>
          </div>
        ) : (
          <YouTube
            className={classNames([classes, roundedEdges ? rounded_edges : null])}
            data-testid="video"
            onPlay={handleOnPlay}
            onStateChange={handleStateChange}
            title={title}
            videoId={videoId}
            opts={opts}
          />
        )}
        {caption && <span>{caption}</span>}
      </div>
    )
  }

  const handleOpenModal = () => {
    setModalIsOpen(true)
    // eslint-disable-next-line
    dataLayer.push({
      event: "modal_click",
      current_url: router.asPath,
      card_title: title
    })
  }

  const handleCloseModal = () => {
    setModalIsOpen(false)
  }

  const getThumbnail = () => {
    return (
      <>
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            handleOpenModal()
            if (window?._paq) {
              window._paq.push([
                "trackEvent",
                "Video Modal Click",
                `Current Page: ${router.asPath}`,
                title
              ])
            }
          }}
          onKeyDown={() => {
            handleOpenModal()
          }}
          className={video_thumbnail_container}
        >
          <Image
            {...thumbnail}
            alt={thumbnail.alt}
            className={classNames(video_thumbnail, roundedEdges ? rounded_edges : null)}
            sizes="(min-width: 960px) 50vw, 100vw"
            style={{ objectFit: "contain" }}
          />
          <button aria-label="Play video" className={video_thumbnail_play_button}>
            <span className={video_thumbnail_play_button_icon} />
          </button>
        </div>
        <ReactModal
          className={video_modal_container}
          isOpen={modalIsOpen}
          overlayClassName={video_modal_overlay}
          onAfterOpen={() => (document.body.style.overflow = "hidden")}
          onAfterClose={() => (document.body.style.overflow = "unset")}
          onRequestClose={() => {
            handleCloseModal()
          }}
          shouldCloseOnOverlayClick={true}
        >
          <div className={video_modal}>
            <div
              role="button"
              tabIndex={0}
              className={video_modal_close_icon_container}
              onClick={() => {
                handleCloseModal()
              }}
              onKeyDown={() => {
                handleCloseModal()
              }}
            >
              <ReactSVG
                className={video_modal_close_icon}
                src={`/icons/x-mark.svg`}
                alt="Close modal"
              />
            </div>

            <YouTube
              className={classNames([classes, roundedEdges ? rounded_edges : null])}
              data-testid="video"
              onPlay={handleOnPlay}
              onStateChange={handleStateChange}
              title={title}
              videoId={videoId}
              opts={opts}
            />
          </div>
        </ReactModal>
      </>
    )
  }

  return thumbnail ? getThumbnail() : getVideo()
}

Video.propTypes = {
  /**
   * Specifies a caption under the video
   */
  caption: PropTypes.string,

  /**
   * Specifies additional class names
   */
  className: PropTypes.string,

  /**
   * Specifies the video title
   */
  title: PropTypes.string,

  /**
   * ID of the youtube video.
   */
  videoUrl: PropTypes.string,

  /**
   * Specifies the video that will loop
   */
  videoFile: PropTypes.object,

  /**
   * Specifies if the video should loop and autoplay
   */
  loopAndAutoplay: PropTypes.bool,

  /**
   * Specifies if the video frame should have a border-radius
   */
  roundedEdges: PropTypes.bool,

  /**
   * Creates an image thumbnail that opens the video in a new modal
   */
  thumbnail: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string.isRequired,
    width: PropTypes.number
  })
}

export default Video
